.legalStationLandingContainer .headerTitlesContainer {
  background: linear-gradient(311.76deg, #D4E7FE -15.24%, #FFFFFF 78.85%);
}

@media (max-width: 767px) {
  .legalStationLandingContainer .headerTitles {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .legalStationLandingContainer .headerTitles .title {
    text-align: center;
  }

  .legalStationLandingContainer .t1 {
    font-size: 12px;
    color: var(--primary);
    margin-top: 30px;
    margin-bottom: 5px;
  }

  .legalStationLandingContainer .t2 {
    font-size: 35px;
    font-weight: 700;
    color: var(--light-black);
    margin: 0;
    line-height: 1.2;
  }

  .legalStationLandingContainer .t3 {
    font-size: 14px;
    color: var(--gray-v2);
    line-height: 2;
    margin-top: 10px;
  }

  .legalStationLandingContainer .headerTitles .button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    background-color: var(--primary);
    font-size: 13px;
    font-weight: 600;
  }
}

@media (min-width: 767px) {
  .legalStationLandingContainer .headerTitles {
    margin-top: 50px;
    width: 86%;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }

  .legalStationLandingContainer .headerTitles .title {
    text-align: center;
  }

  .legalStationLandingContainer .t1 {
    font-size: 12px;
    color: var(--primary);
    margin-top: 30px;
    margin-bottom: 5px;
  }

  .legalStationLandingContainer .t2 {
    font-size: 35px;
    font-weight: 700;
    color: var(--light-black);
    margin: auto;
    line-height: 1.2;
    max-width: 70vw
  }

  .legalStationLandingContainer .t3 {
    font-size: 14px;
    color: var(--gray-v2);
    line-height: 2;
    margin-top: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .legalStationLandingContainer .headerTitles .button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    background-color: var(--primary);
    font-size: 13px;
    font-weight: 600;
  }
}

@media (min-width: 1024px) {
  .legalStationLandingContainer .t2 {
    font-size: 56px;
    max-width: 75vw
  }

  .legalStationLandingContainer .t3 {
    font-size: 18px;
    max-width: 70%;
  }
}

@media (min-width: 1300px) {
  .legalStationLandingContainer .headerTitles {
    padding-left: 0vw;
    padding-right: 0vw;
    width: 900px;
  }
}