.step2-views {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  gap: 15px;
}

.step2-title {
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 86px;
  font-weight: 700;
  background: linear-gradient(
    180deg,
    rgba(105, 113, 155, 0.1) 0%,
    rgba(62, 70, 108, 0.374) 200%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step2-question {
  margin: 0;
  padding: 0;
  color: #051259;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  width: 600px;
}

.step2-choices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.step2-choices {
  display: flex;
  border-radius: 5px;
  flex-shrink: 0;
  width: 60vw;
  cursor: pointer;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    background: #3392ff;
    border-radius: 50%;
  }
  h3 {
    flex-grow: 1;
    color: #051259;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
  }
}
