/* Base Styles */
.frequentlyAskedQuestionsContainer {
  padding: 16px;
  background-color: var(--white);
}

.frequentlyAskedQuestions {
  max-width: 1200px;
  margin: 0 auto;
}

.questions .question {
  padding: 25px 40px 25px 40px;
  background-color: var(--primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  transition: all 0.3s ease;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .frequentlyAskedQuestionsContainer {
    padding: 16px;
  }

  .frequentlyAskedQuestionsText .title.t2 {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }

  .frequentlyAskedQuestionsText .title.t3 {
    font-size: 1rem;
    margin-bottom: 24px;
  }

  .questions .question {
    padding: 20px;
    border-radius: 16px;
    
  }

  .questions .faq-item {
    width: 100%;
    height: auto;
  }

  .questions .faq-item h2 {
    font-size: 1.25rem;
  }

  .questions .faq-item p {
    font-size: 1rem;
    line-height: 1.5;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .frequentlyAskedQuestionsText .title.t2 {
    font-size: 2rem;
    margin-bottom: 16px;
  }

  .frequentlyAskedQuestionsText .title.t3 {
    font-size: 1.125rem;
    margin-bottom: 32px;
  }

  .questions .faq-item {
    width: 100%;
    height: auto;
  }

  .questions .faq-item h2 {
    font-size: 1.5rem;
  }

  .questions .faq-item p {
    font-size: 1rem;
    line-height: 1.6;
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .questions .faq-item {
    width: 100%;
    margin: 0 auto;
    height: auto;
  }
}

