@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');

:root {
  --up: 1;
  --card-margin: 12px;
  --card-shadow: rgba(46, 49, 52, 0.4);
  --color-white: #fff;
  --color-lightgrey: #ffffff;
  --color-ui: #999;
  --color-ui-hover: #666;
  --cardWidth: calc(76vw / var(--up));
  --carouselWidth: calc(var(--cardWidth) * var(--num));
  --carouselLeftMargin: calc(2vw - var(--cardWidth));

  @media screen and (max-width: 900px) {
    /*basically mobile*/
    --up: 1;
  }
}

.carouselwrapper {
  overflow: hidden;
  background: var(--color-lightgrey);
  position: relative;
  width: 80vw;
}

.carousel {
  width: var(--carouselWidth);
  margin: 0 0 0 var(--carouselLeftMargin);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  &.prev {
    animation-duration: 0.6s;
    animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    animation-name: prev;
  }

  &.next {
    animation-duration: 0.6s;
    animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    animation-name: next;
  }

  li {
    padding: 50px;
    text-align: center;
    width: calc(var(--cardWidth) - var(--card-margin) * 2);
    box-sizing: border-box;
  }
}

.ui {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: calc(100% - var(--card-margin) * 2);
  justify-content: space-between;
  padding: var(--card-margin);
  z-index: 100;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-ui);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-white);
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
    border: 0;
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);

    &:hover {
      background: var(--color-ui-hover);
    }

    &:focus {
      outline: none;
      border: 1px solid rgba(255, 255, 255, 1);
    }
  }
}

.card {
  box-shadow: 0px 1px 2px 0px var(--card-shadow);
  border-radius: 4px;
  margin: var(--card-margin);
  background: var(--color-white);

  .material-icons {
    font-size: 4.8rem;
  }

  .what {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 3rem;
  }

  .who {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
}

@keyframes next {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}

@keyframes prev {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}
