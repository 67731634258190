/*----------Inter-----------*/
@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('../../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 15px;
  src: url('../../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('../../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
}

/*----------Mukta-----------*/
@font-face {
  font-family: 'Mukta';
  font-weight: 800;
  src: url('../../assets/fonts/Mukta/MuktaMahee-ExtraBold.ttf') format('truetype');
}

/*---------Rubik------------*/
@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src: url('../../assets/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src: url('../../assets/fonts/Rubik/Rubik-Medium.ttf') format('truetype');
}

/*---------Circular Std------------*/
@font-face {
  font-family: 'Circular std';
  font-weight: 400;
  src: url('../../assets/fonts/Circular/CircularStd-Black.otf') format('truetype');
}
@font-face {
  font-family: 'Circular std';
  font-weight: 700;
  src: url('../../assets/fonts/Circular/CircularStd-Bold.otf') format('truetype');
}

/*---------Roboto------------*/
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

/*---------Poppins------------*/
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}