.login-container{
  background-color: var(--light-primary-v3);
  min-height: 100vh;
}
.login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.login .alert{
  margin-bottom: 20px;
  border: solid 1px rgba(0, 128, 0, 0.288);
  width: 400px;
}
.login-form{
  background-color: var(--white);
  width: 400px;
  border-radius: 5px;
  border: solid 1px var(--gray-v4);
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.login-form .title{
  color: var(--light-black);
  font-weight: 700;
  text-align: start;
  width: 88%;
  margin-bottom: 20px;
}
.login-form input[type='email'], .login-form input[type='password']{
  width: 330px;
  padding: 10px;
  font-size: 12px;
}
.email, .password{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--light-black);
  font-size: 12px;
  height: 55px;
  margin-bottom: 10px;
}
.resterConnecte{
  width: 88%;
  margin-bottom: 10px;
  position: relative;
  left: 14px;
}
.resterConnecte span{
  font-size: 12px;
  padding: 0px;
  margin-right: 10px;
}
.login-form button{
  width: 88%;
}
.login-link-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 88%;
  margin-bottom: 20px;
  margin-top: 10px;
}
.login-form a{
  color: var(--primary);
  text-decoration: none;
}
.login-form a:hover{
  color: var(--black);
}