@import '../../assets/theme/colors.css';
@import '../../assets/theme/font.css';

body{
  font-family: 'Inter' sans-serif;
}

.legalStationLandingContainer{
  display: flex;
  flex-direction: column;
}
