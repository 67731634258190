.inscription-container{
  background-color: var(--light-primary-v3);
  min-height: 100vh;
}
.inscription{
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 50px;
}
.inscription-form{
  background-color: var(--white);
  width: 400px;
  border-radius: 5px;
  border: solid 1px var(--gray-v4);
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.inscription-form .title{
  color: var(--light-black);
  font-weight: 700;
  text-align: start;
  width: 88%;
  margin-bottom: 20px;
}
.inscription-form input{
  width: 330px;
  padding: 10px;
  font-size: 12px;
}
.inscription label{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--light-black);
  font-size: 12px;
  height: 55px;
  margin-bottom: 10px;
}
.name-container{
  display: flex;
  justify-content: space-between;
  width: 88%;
}
.nom, .prenom{
  width: 48%;
}
.inscription-form button{
  width: 89%;
  margin-bottom: 20px;
  margin-top: 10px;
}

.inscription-link-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 88%;
  margin-bottom: 20px;
  margin-top: 10px;
}

.inscription-form a{
  color: var(--primary);
  text-decoration: none;
}
.inscription-form a:hover{
  color: var(--black);
}