.circle-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: linear-gradient(154.49deg, #5CA1FE 6.61%, #217BF4 89.72%);
  border-radius: 50%;
}

.service-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.service-description {

  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

@media (max-width: 767px) {

  .services {

    background-color: var(--white);
    padding: 20px 7vw;
  }

  .services h1 {
    font-size: 35px;
    font-weight: 700;
    color: var(--light-black);
    margin: 0;
    line-height: 1.2;
    text-align: center;

  }



}

@media (min-width: 767px) {

  .services {

    background-color: var(--white);
    padding: 50px 7vw;
  }

  .services h1 {
    font-size: 35px;
    font-weight: 700;
    color: var(--light-black);
    margin: 0;
    line-height: 1.2;

  }

}