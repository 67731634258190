@media (max-width: 767px) {
  .headerImage .logoContainer{
    background-color: var(--white);
  }
  .headerImage .logo{
    margin: 10px 5vw;
    width: 20vw;
  }
  .imageHeaderContainer p{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 15vw;
    font-size: 30px;
    font-weight: 700;
    color: var(--white);
  }
  .imageHeaderContainer .imageHeader{
    width: 100%;
  }
  .modeleContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modele{
   display: none;
  }
  .modele-767{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    margin-top: 20px;
    padding: 0 20px;
    width: 60vw;
    cursor: pointer;
  }
  .modele-767:hover p, .modele-767:hover .icon{
    opacity: 0.8;
  }
  .modele-767 p{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: var(--dark-blue);
  }
  .modele-767-link{
    text-decoration: none;
  }
}

@media (min-width: 767px) {
  .headerImage .logoContainer{
    background-color: var(--white);
  }
  .headerImage .logo{
    margin: 10px 5vw;
    width: 12vw;
  }
  .imageHeaderContainer p{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 13vw;
    font-size: 40px;
    font-weight: 700;
    color: var(--white);
  }
  .imageHeaderContainer .imageHeader{
    width: 100%;
  }
  .modeleContainer{
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 9vw;
  }
  .modele-767{
    display: none;
  }
  .modele{
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 30px;
    margin:20px 20px 0 20px;
    width: 20%;
  }
  .modele .button{
    margin-bottom: 20px;
  }
  .modele p{
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    color: var(--dark-blue);
    width: 240px;
  }
}

@media (min-width: 1500px) {
  .modeleContainer{
    width: 1300px;
    margin: auto;
  }
}
