@media (max-width: 767px) {
  .headerContainer{
    margin-top: 0;
  }
  .headerContainer .logoContainer{
    padding: 2px;
    background-color: var(--white);
  }
  .headerContainer .logo{
    margin: 10px 5vw;
    width: 20vw;
  }
}

@media (min-width: 767px) {
  .headerContainer{
    margin-top: 0;
  }
  .headerContainer .logoContainer{
    padding: 2px;
    background-color: var(--white);
  }
  .headerContainer .logo{
    margin: 10px 5vw;
    width: 12vw;
  }
}
