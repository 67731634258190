@media (max-width: 767px) {
  .handleContent{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .leftContent{
    display: none;
  }
}

@media (min-width: 767px) {
  .handleContent{
    display: flex;
    justify-content: space-evenly;
  }
  .leftContent{
    width: 30%;
    margin-bottom: 10px;
  }
  .leftContent .left{
    margin-top: 30px;
    position: sticky;
    top: 50px;
  }
  .choisirModelContainer{
    /* background-color: #F0F0F0; */
    border-radius: 5px;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .choisirModelContainer p{
    font-size: 17.67px;
    font-weight: 700;
    width: 90%;
    text-align: center;
    line-height: 1.5;
  }
  .choisirModelContainer .button{
    padding: 10px 40px;
    width: 100%;
  }
  .sommaire{
    color: #CCCCCC;
    font-family: 'Circular std';
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
  }
  .leftContent .link{
    text-decoration: none;
    color: var(--gray-v3);
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .leftContent .link:hover{
    color: var(--link);
  }
}

@media (min-width: 1500px) {
  .handleContent{
    width: 1300px;
    margin: auto;
  }
}