.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
}

.background-white {
  background-color: #ffffff;
}

.background-gradient {
  background: linear-gradient(311.76deg, #d4e7fe -15.24%, #ffffff 78.85%);

}

@media (max-width: 767px) {

  /* Hide menu items and login button for mobile */
  .menuItemsContainer-1024,
  .login_button {
    display: none;
    display: none !important;

  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }

  .logoLegalStation {
    width: 50%;
    max-width: 200px;
  }

  /* Mobile menu toggle visibility */
  .buttonMenuIcon {
    display: block;
  }

  /* Mobile menu items container */
  .menuItemsContainer-767 {
    width: 97%;
    margin-top: 10px;
    margin: auto;
    background-color: var(--white);
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    height: 0;
  }

  .menuItemsContainerActive {
    height: calc(40px * 6 + 20px);
    /* Height of 6 items + margins */
  }

  .menuItemsContainerDisactivate {
    height: 0;
  }

  .item {
    display: block;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    height: 40px;
    margin: 0;
    color: var(--gray-v2);
  }

  .item:hover {
    cursor: pointer;
    color: var(--primary);
  }

  .activeItem {
    color: var(--primary);
  }
}

@media (min-width: 767px) {

  .buttonMenuIcon,
  .menuItemsContainer-767 {
    display: none !important;
  }



  .header {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    align-items: center;
  }

  .logoLegalStation {
    width: 10vw;
    max-width: 200px;
  }

  .menuItemsContainer-1024 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
  }

  .item {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: var(--gray-v2);
  }

  .item:hover {
    cursor: pointer;
    color: var(--primary);
  }

  .activeItem {
    color: var(--primary);
  }
}

@media (min-width: 1300px) {
  .header {
    max-width: 1100px;
    margin: auto;
  }
}