@media (max-width: 767px) {

  .pourquoiLegalStation{

    background-color: var(--white);
    padding: 20px 7vw;
  }
  .pourquoiLegalStation h1{
    font-size: 35px;
    font-weight: 700;
    color: var(--light-black);
    margin: 0;
    line-height: 1.2;
    text-align: center;

  }
 
}

@media (min-width: 767px) {

  .pourquoiLegalStation{
   
    background-color: var(--white);
    padding: 50px 7vw;
  }
  .pourquoiLegalStation h1{
    font-size: 35px;
    font-weight: 700;
    color: var(--light-black);
    margin: 0;
    line-height: 1.2;

  }
 
}

@media (min-width: 1300px) {
  .pourquoiLegalStationContainer{
    background-color: var(--white);
  }
  .pourquoiLegalStation{
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
}