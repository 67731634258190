@media (max-width: 767px) {
  .connecteBanqueContainer{
    background-color: var(--light-primary-v4);
    margin-top: -100px;
    padding: 50px;
  }
  .connecteBanque h1{
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    font-family: 'Inter';
    color: var(--dark-blue);
    width: 80%;
    margin: auto;
  }
  .connecteBanque p{
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-family: 'Inter';
    color: var(--dark-blue);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    line-height: 1.5;
  }
  .connecteBanqueImagesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .connecteBanqueImagesContainer img{
    width: 85vw;
  }
}

@media (min-width: 767px) {
  .connecteBanqueContainer{
    background-color: var(--light-primary-v4);
    padding: 80px;
  }
  .connecteBanque h1{
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    font-family: 'Inter';
    color: var(--dark-blue);
    width: 80%;
    margin: auto;
  }
  .connecteBanque p{
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-family: 'Inter';
    color: var(--dark-blue);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    line-height: 1.5;
  }
  .connecteBanqueImagesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .connecteBanqueImagesContainer img{
    width: 85vw;
  }
}

@media (min-width: 1300px) {
  .connecteBanque{
    width: 1150px;
    margin: auto;
  }
  .connecteBanqueImagesContainer img{
    width: 975px;
  }
}