.legalStationLandingContainer .navToFormContainer {
  background: linear-gradient(311.76deg, #D4E7FE -15.24%, #FFFFFF 78.85%);
  margin-bottom: 0;
  
}

@media (max-width: 767px) {
  .navToFormContainer{
    margin-top: 25px;
  }
  .blocFormItems{
    width: 88%;
    height: 205px;
    margin: auto;
  }
  .blocFormItems .title{
    color: var(--white);
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 30px;
    
  }
  .blocFormItems .description{
    color: var(--white);
    font-size: 15px;
    margin-left: 30px;
    margin-bottom: 25px;
  }
  .creationEntreprise{
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: var(--light-blue);
    background-image: url(../../../../assets/images/blueBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .domiciliation{
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: var(--light-red);
    background-image: url(../../../../assets/images/redBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .contrat{
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: var(--light-yellow);
    background-image: url(../../../../assets/images/yellowBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .autreDemarche{
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: var(--light-green);
    background-image: url(../../../../assets/images/greenBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .formItemsContainer{
    opacity: 0;
    display: inline-block;
    height: 205px;
    width: inherit;
    background-color: var(--white);
    position: absolute;
    transition-property: opacity;
    transition-duration: 2s;
    display: flex;
    flex-direction: column;
  }
  .creationEntreprise:hover + .creationEntrepriseItems, .creationEntrepriseItems:hover{
    opacity: 1;
  }
  .domiciliation:hover + .domiciliationItems, .domiciliationItems:hover{
    opacity: 1;
  }
  .contrat:hover + .contratItems, .contratItems:hover{
    opacity: 1;
  }
  .autreDemarche:hover + .autreDemarcheItems, .autreDemarcheItems:hover{
    opacity: 1;
  }
  .formItem{
    text-decoration: none;
    margin-left: 5px;
    font-size: 19px;
    color: var(--black);
    width: 86%;
    padding: 10px;
  }
  .formItem:hover{
    text-decoration: none;
    border-radius: 10px;
    background-color: var(--light-gray-v2);
  }
}

@media (min-width: 767px) {
  .navToFormContainer{
    margin-top: 30px;
  }
  .navToForm{
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    height: 280px;
  }
  .blocFormItems{
    width: 25%;
  }
  .blocFormItems .title{
    color: var(--white);
    font-size: 22px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    
  }
  .blocFormItems .description{
    color: var(--white);
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
    line-height: 1.2;
  }
  .creationEntreprise{
    height: 280px;
    background-color: var(--light-blue);
    background-image: url(../../../../assets/images/blueBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top-left-radius: 20px;
  }
  .domiciliation{
    height: 280px;
    background-color: var(--light-red);
    background-image: url(../../../../assets/images/redBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .contrat{
    height: 280px;
    background-color: var(--light-yellow);
    background-image: url(../../../../assets/images/yellowBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .autreDemarche{
    height: 280px;
    background-color: var(--light-green);
    background-image: url(../../../../assets/images/greenBlock.png);
    background-position: 70px 15px;
    background-repeat: no-repeat;
    background-size: 80% auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top-right-radius: 20px;
  }
  .formItemsContainer{
    position: relative;
    bottom: 280px;
    height: 280px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 2s;
  }
  .creationEntreprise:hover + .creationEntrepriseItems, .creationEntrepriseItems:hover{
    opacity: 1;
  }
  .domiciliation:hover + .domiciliationItems, .domiciliationItems:hover{
    opacity: 1;
  }
  .contrat:hover + .contratItems, .contratItems:hover{
    opacity: 1;
  }
  .autreDemarche:hover + .autreDemarcheItems, .autreDemarcheItems:hover{
    opacity: 1;
  }
  .formItem{
    text-decoration: none;
    margin-left: 5px;
    font-size: 19px;
    color: var(--black);
    width: 98%;
    padding: 10px;
  }
  .formItem:hover{
    text-decoration: none;
    border-radius: 10px;
    background-color: var(--light-gray-v2);
  }
}

@media (min-width: 767px) and (max-width: 1000px) {
  .formItem{
    text-decoration: none;
    margin-left: 5px;
    font-size: 16px;
    color: var(--black);
    width: 98%;
    padding: 5px;
  }
}

@media (min-width: 1300px) {
  .navToForm{
    width: 1110px;
    margin: auto;
  }
}