@media (max-width: 767px) {
  .creationEntrepriseContainer .headerTitles{
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .creationEntrepriseContainer .headerTitles .title{
    text-align: center;
  }
  .creationEntrepriseContainer .t1{
    font-size: 38px;
    color: var(--light-black);
    margin-top: 30px;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .creationEntrepriseContainer .t2{
    font-size: 28px;
    font-weight: 300;
    color: var(--light-black);
    margin: 0;
    line-height: 1.2;
  }
  .creationEntrepriseContainer .t3{
    font-size: 16px;
    color: var(--gray-v2);
    line-height: 2;
    margin-top: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .creationEntrepriseContainer .headerTitles .button{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    background-color: var(--primary);
    font-size: 13px;
    font-weight: 600;
  }
}

@media (min-width: 767px) and (max-width: 925px){
  .creationEntrepriseContainer .headerTitles{
    margin-top: 80px;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }
  .creationEntrepriseContainer .headerTitles .title{
    text-align: center;
  }
  .creationEntrepriseContainer .t1{
    font-size: 56px;
    color: var(--light-black);
    margin-top: 30px;
    margin-bottom: 0px;
    font-weight: 700;
  }
  .creationEntrepriseContainer .t2{
    font-size: 40px;
    font-weight: 300;
    color: var(--light-black);
    margin: auto;
    margin-bottom: 30px;
    line-height: 60px;
    max-width: 70vw
  }
  .creationEntrepriseContainer .t3{
    font-size: 18px;
    color: var(--gray-v2);
    line-height: 28px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .creationEntrepriseContainer .headerTitles .button{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    background-color: var(--primary);
    font-size: 13px;
    font-weight: 600;
  }
}

@media (min-width: 925px) {
  .creationEntrepriseContainer .headerTitles{
    margin-top: 80px;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }
  .creationEntrepriseContainer .headerTitles .title{
    text-align: center;
  }
  .creationEntrepriseContainer .t1{
    font-size: 66px;
    color: var(--light-black);
    margin-top: 30px;
    margin-bottom: 0px;
    font-weight: 700;
  }
  .creationEntrepriseContainer .t2{
    font-size: 46px;
    font-weight: 300;
    color: var(--light-black);
    margin: auto;
    margin-bottom: 30px;
    line-height: 60px;
    max-width: 70vw
  }
  .creationEntrepriseContainer .t3{
    font-size: 18px;
    color: var(--gray-v2);
    line-height: 28px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 850px;
  }
  .creationEntrepriseContainer .headerTitles .button{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    background-color: var(--primary);
    font-size: 13px;
    font-weight: 600;
  }
}

@media (min-width: 1300px) {
  .creationEntrepriseContainer .headerTitles{
    padding-left: 0vw;
    padding-right: 0vw;
    width: 900px;
  }
}