/* Global Styles */
.contactUsContainer {
  background-color: var(--white);
  width: 100%;
  padding: 0 16px; /* Ensure consistent padding */
}

.contactUsText {
  background: linear-gradient(251.14deg, rgba(248, 248, 248, 0.9) 62.73%, rgba(248, 248, 248, 0.9) 62.73%);
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 40px 16px; /* Added padding for better spacing */
}

.contactUsText .title {
  text-align: center;
}

.contactUs {
  background-color: var(--white);
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap; 
}

.contactUsContainer .t1 {
  font-size: 12px;
  color: var(--primary);
  margin: 30px 0;
}

.contactUsContainer .t2 {
  font-size: 35px;
  font-weight: 700;
  color: var(--light-black);
  margin: 0 auto;
  line-height: 1.2;
}

.contactUsContainer .t3 {
  font-size: 14px;
  color: var(--gray-v2);
  line-height: 1.6;
  margin-top: 20px;
  max-width: 768px;
}

/* Button Styles */
.contactUsText .button {
  margin: 35px auto 5px auto;
  padding: 12px 30px;
  border-radius: 10px;
  background-color: var(--primary);
  font-size: 13px;
  font-weight: 600;
}

/* Content-Side Styles */
.content-side , .image-side {
  max-width: 600px;
  margin: auto;
  text-align: left; /* Left-align content by default */
}

.content-side h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  color: #181059;
  margin-bottom: 32px;
}

.content-side p {
  font-size: 16px;
  color: #6d6d6d;
  margin-bottom: 40px;
}

/* Card Styles */
.contactUs-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 24px;
  border: 2px solid #1b1b1b;
}

.contactUs-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.contactUs-card-description {
  font-size: 14px;
  color: #6d6d6d;
  line-height: 1.5;
}

/* Responsive Styles */

/* Medium screens (768px - 1024px) */
@media (max-width: 1024px) {
  .contactUs {
    flex-direction: column;
    align-items: center;
  }

  .content-side {
    max-width: 100%;
    text-align: center;
  }

  .content-side h1 {
    font-size: 24px;
    line-height: 1.4;
    max-width: 100%;
  }

  .content-side p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

/* Small screens (480px - 768px) */
@media (max-width: 768px) {
  .contactUsText .t1 {
    font-size: 18px;
  }

  .contactUsText .t2 {
    font-size: 24px;
    margin-bottom: 15px;
    max-width: 100% !important;
  }

  .contactUsText .t3 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .contactUs-card {
    padding: 15px;
  }

  .contactUs-card-title {
    font-size: 18px;
  }

  .contactUs-card-description {
    font-size: 12px;
  }
}

/* Extra-small screens (less than 480px) */
@media (max-width: 480px) {
  .contactUsContainer {
    padding: 20px;
  }

  .contactUsText .button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .image-side img {
    max-width: 100%;
  }
}
