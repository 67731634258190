.forgot-password-container {
  max-width: 400px;
  margin: 90px auto;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  
}

.forgot-password-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.forgot-password-container .MuiTextField-root {
  margin-bottom: 20px;
  width: 100%;
}

.forgot-password-container .MuiButton-root {
  width: 100%;
  padding: 10px 0;
  background-color: #1976d2;
  color: #fff;
  text-transform: none;
}

.forgot-password-container .MuiButton-root:hover {
  background-color: #1565c0;
}

.alert {
  margin-bottom: 20px;
}
