.pourquoiDomiciliationLegalStation .title{
  font-family: 'Inter';
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
  color: var(--title-content);
}

.pourquoiDomiciliationLegalStation .itemContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  text-align: center;
  font-size: 20px;
}

.pourquoiDomiciliationLegalStation .itemContainer .ItemTitle{
  color: var(--title-content);
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 20px;
}

.pourquoiDomiciliationLegalStation .itemPourquoiDomiciliationLegalStationContainer{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

.itemEtapesDomiciliationLegalStationContainer{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

.etapesDomiciliation{
  width: 95%;
  padding: 20px;
  background-color: #f6f9fc;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.etapeDomiciliationTitle{
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.itemEtapesDomiciliationLegalStationContainer .itemContainer .ItemTitle{
  width: 100%;
  text-align: start;
}

.itemEtapesDomiciliationLegalStationContainer .itemContainer p{
  width: 100%;
  text-align: start;
}

.itemEtapesDomiciliationLegalStationContainer .itemContainer img{
  align-self: flex-start;
}

.etapesDomiciliationButtonContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}


@media (max-width: 890px){
  .pourquoiDomiciliationLegalStation .title{
    width: 90%;
    margin: auto;
    padding-bottom: 40px;
  }

  .pourquoiDomiciliationLegalStation .itemPourquoiDomiciliationLegalStationContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
  }

  .itemEtapesDomiciliationLegalStationContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: auto;
  }

  .pourquoiDomiciliationLegalStation .itemContainer{
    width: 100%;
    margin-bottom: 40px;
  }

  .etapesDomiciliationButtonContainer{
    width: 95%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px !important;
  }
}

@media (min-width: 1300px) {
  .pourquoiDomiciliationLegalStation{
    width: 1100px;
    margin: auto;
  }
}