.headerDomiciliation{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4vw;
}

.headerDomiciliation .leftBlock{
  width: 60%;
}

.headerDomiciliation .title{
  font-family: 'Inter';
  font-weight: 700;
  font-size: 56px;
  line-height: 1.2;
  color: var(--title-content);
  margin-bottom: 20px;
}

.headerDomiciliation .listHeaderContainer li{
  font-family: 'Inter';
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
}

.headerDomiciliation .type-list{
  color: #14cf96;
  font-weight: 700;
}

.headerDomiciliation .buttonHeaderContainer button{
  margin-top: 30px;
  margin-right: 20px;
}

.headerDomiciliation .domiciliationImageHeader{
  width: 255px;
  height: 200px;
  position: relative;
  right: 4vw;
}

@media (max-width: 890px){
  .headerDomiciliation{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headerDomiciliation .leftBlock{
    width: 100%;
  }
  
  .headerDomiciliation .title{
    text-align: center;
    font-size: 35px;
  }
  
  .headerDomiciliation .listHeaderContainer li{
    text-align: center;
  }

  .headerDomiciliation .buttonHeaderContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .headerDomiciliation .domiciliationImageHeader{
    right: 0vw;
  }
}

@media (min-width: 1300px) {
  .headerDomiciliation{
    padding-top: 50px;
    width: 1100px;
    margin: auto;
  }

  .headerDomiciliation .domiciliationImageHeader{
    right: 0vw;
  }
}

