.startYourBusinessContainer {
    display: flex;
    justify-content: center;
    margin-top: 177px;
    margin-bottom: 65px;
    padding: 0 20px;
  }
  
  .startYourBusiness {
    width: 100%;
    max-width: 1240px;
    height: auto;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    background: linear-gradient(311.76deg, #D4E7FE -15.24%, #FFFFFF 78.85%);
    border-radius: 40px;
    padding: 40px;
  }
  
  /* Content-Side Styles */
  .content-side {
    max-width: 600px;
  }
  
  .content-side h1 {
    font-weight: 700;
    font-size: 44px;
    line-height: 54px;
    color: #222222;
  }
  
  .content-side p {
    font-size: 16px;
    color: #6d6d6d;
    margin-bottom: 40px;
  }
  
  .image-side img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
  }
  
  /* Responsive Styles */
  
  /* For screens smaller than 1024px */
  @media (max-width: 1024px) {
    .startYourBusiness {
      flex-direction: column;
      text-align: center;
      padding: 30px;
    }
  
    .content-side {
      max-width: 90%;
    }
  
    .content-side h1 {
      font-size: 32px;
      line-height: 40px;
    }
  
    .content-side p {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  
  /* For screens smaller than 768px */
  @media (max-width: 768px) {
    .startYourBusinessContainer {
      padding: 0 15px;
    }
  
    .content-side h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .content-side p {
      font-size: 12px;
    }
  }
  
  /* For screens smaller than 480px */
  @media (max-width: 480px) {
    .startYourBusiness {
      gap: 12px;
    }
  
    .content-side h1 {
      font-size: 24px;
      line-height: 30px;
    }
  
    .content-side p {
      font-size: 12px;
      margin-bottom: 15px;
    }
  
    .image-side img {
      max-width: 100%;
    }
  }
  