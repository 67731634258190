#formContratTravail{
  padding: 50px 15vw;
}
#formContratTravail h1{
  font-size: 23px;
  font-weight: 700;
  font-family: 'Inter';
  color: var(--title-content);
  margin: 0;
}
#formContratTravail .entrepriseIndividuContainer{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 50px;
}
#formContratTravail .entrepriseIndividuContainer button{
  padding: 20px;
  width: 46%;
}
#formContratTravail .formContainer{
  background-color: var(--white);
  padding: 10px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-radius: 5px;
}

#formContratTravail .maxWidthBloc{
  max-width: 500px;
}

#formContratTravail h3{
  font-size: 14px;
  font-weight: 700;
  font-family: 'Inter';
  color: var(--title-content);
  margin: 15px 0px;
}
#formContratTravail .MuiFormControlLabel-label{
  font-size: 14px;
  color: var(--title-content);
}
#formContratTravail .infoAlert{
  margin-top: 15px;
  margin-bottom: 15px;
}