@media (max-width: 767px) {
  .contratTravailContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  .contratTravailContent h3{
    font-size: 23px;
    font-weight: 700;
    font-family: 'Circular std';
    color: var(--title-content);
    width: 100%;
    margin-bottom: 0px;
  }
  .contratTravailContent h6{
    font-size: 17px;
    font-family: 'Circular std';
    color: var(--title-content);
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contratTravailContent ul{
    list-style-type: disc;
    margin-left: 40px;
    margin-bottom: 20px;
  }
  .contratTravailContent li{
    font-size: 16px;
    line-height: 1.5;
  }
  .contratTravailContent p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .infoContent{
    background-color: var(--info-background);
    color: var(--text-info);
    padding: 15px;
    border: solid 1px var(--info-border);
    border-radius: 4px;
  }
  .linkContent{
    color: var(--link);
    text-decoration: none;
  }
  .linkContent:hover{
    color: var(--black);
  }
}

@media (min-width: 767px) {
  .contratTravailContent{
    width: 60%;
    margin-bottom: 10px;
  }
  .contratTravailContent h3{
    font-size: 23px;
    font-weight: 700;
    font-family: 'Circular std';
    color: var(--title-content);
  }
  .contratTravailContent h6{
    font-size: 17px;
    font-family: 'Circular std';
    color: var(--title-content);
  }
  .contratTravailContent ul{
    list-style-type: disc;
    margin-left: 40px;
    margin-bottom: 20px;
  }
  .contratTravailContent li{
    font-size: 16px;
    line-height: 1.5;
  }
  .contratTravailContent p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .infoContent{
    background-color: var(--info-background);
    color: var(--text-info);
    padding: 15px;
    border: solid 1px var(--info-border);
    border-radius: 4px;
  }
  .linkContent{
    color: var(--link);
    text-decoration: none;
  }
  .linkContent:hover{
    color: var(--black);
  }
}