.footerContainer {
  background: linear-gradient(311.76deg, #D4E7FE -15.24%, #FFFFFF 78.85%);
  padding: 50px 20px;
  border-radius: 40px 40px 0px 0px;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}

.footer div {
  flex: 1;
  min-width: 200px;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 10px;
}

.footer-logo p {
  font-size: 14px;
  color: #000;
  text-align: center;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer p {
  font-size: 14px;
  margin: 5px 0;
  color: #333;
}

.copywriteContainer {
  background: linear-gradient(311.76deg, #D4E7FE -15.24%, #FFFFFF 78.85%);
  color: #000;
  padding: 20px;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copywrite {
  max-width: 100%;
  text-align: center;
}

.copywrite .flex {
  flex-wrap: wrap;
  justify-content: center;
}

.copywrite p {
  margin: 10px 5px;
}

@media (max-width: 1024px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer div {
    min-width: 180px;
  }
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer div {
    margin-bottom: 20px;
  }

  .footer-logo img {
    margin: 0 auto 10px;
  }
}

@media (max-width: 480px) {
  .footer h4 {
    font-size: 14px;
  }

  .footer p {
    font-size: 12px;
  }

  .footer-logo img {
    width: 120px;
  }

  .copywrite p {
    font-size: 11px;
  }
}
