@media (max-width: 767px) {
  .dissolutionContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  .dissolutionContent h3{
    font-size: 23px;
    font-weight: 700;
    font-family: 'Circular std';
    color: var(--title-content);
    width: 100%;
    margin-bottom: 0px;
  }
  .dissolutionContent h6{
    font-size: 17px;
    font-family: 'Circular std';
    color: var(--title-content);
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .dissolutionContent .h7{
    font-size: 15px;
    font-family: 'Circular std';
    color: var(--title-content);
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .dissolutionContent li{
    font-size: 16px;
    line-height: 1.5;
  }
  .dissolutionContent p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .infoContent{
    background-color: var(--info-background);
    color: var(--text-info);
    padding: 15px;
    border: solid 1px var(--info-border);
    border-radius: 4px;
  }
  .linkContent{
    color: var(--link);
    text-decoration: none;
  }
  .linkContent:hover{
    color: var(--black);
  }
  .list-wtl{
    list-style-type: none;
  }
  .dissolutionContent table{
    border-collapse: collapse;
    width: 100%;
  }
  .dissolutionContent table td, .dissolutionContent table th{
    border: solid 1px var(--gray-v3);
  }
  .infoContentBlue{
    background-color: var(--info-background-blue);
    color: var(--dark-blue);
    padding: 15px;
    border: solid 1px var(--info-background-blue);
    border-radius: 4px;
  }
  .infoContentRed{
    background-color: var(--light-red-v2);
    color: var(--red);
    padding: 15px;
    border: solid 1px var(--light-red-v3);
    border-radius: 4px;
  }
  .faq{
    background-color: var(--white);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
    padding: 5px 20px;
    margin-bottom: 20px;
  }
  .faq h5{
    color: var(--dark-blue);
    font-size: 17px;
    font-weight: 700;
    font-family: 'Circular std';
    margin-bottom: 10px;
  }
}

@media (min-width: 767px) {
  .dissolution{
    margin: 0px;
    background-color: var(--light-primary-v3);
  }
  .dissolutionContent{
    width: 60%;
    margin-bottom: 10px;
  }
  .dissolutionContent h3{
    font-size: 23px;
    font-weight: 700;
    font-family: 'Circular std';
    color: var(--title-content);
  }
  .dissolutionContent h6{
    font-size: 17px;
    font-family: 'Circular std';
    color: var(--title-content);
  }
  .dissolutionContent .h7{
    font-size: 15px;
    font-family: 'Circular std';
    color: var(--title-content);
  }
  .dissolutionContent li{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }
  .dissolutionContent p{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .infoContent{
    background-color: var(--info-background);
    color: var(--text-info);
    padding: 15px;
    border: solid 1px var(--info-border);
    border-radius: 4px;
  }
  .linkContent{
    color: var(--link);
    text-decoration: none;
  }
  .linkContent:hover{
    color: var(--black);
  }
  .dissolutionContent .list-wtl{
    list-style-type: disc !important;
    margin-left: 40px;
  }
  .dissolutionContent table{
    border-collapse: collapse;
    width: 100%;
  }
  .dissolutionContent table td, .dissolutionContent table th{
    border: solid 1px var(--gray-v3);
    width: calc(100% / 3);
    padding: 5px;
  }
  .infoContentBlue{
    background-color: var(--info-background-blue);
    color: var(--dark-blue);
    padding: 15px;
    border: solid 1px var(--info-background-blue);
    border-radius: 4px;
  }
  .infoContentRed{
    background-color: var(--light-red-v2);
    color: var(--red);
    padding: 15px;
    border: solid 1px var(--light-red-v3);
    border-radius: 4px;
  }
  .faq{
    background-color: var(--white);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
    padding: 5px 20px;
    margin-bottom: 20px;
  }
  .faq h5{
    color: var(--dark-blue);
    font-size: 17px;
    font-weight: 700;
    font-family: 'Circular std';
    margin-bottom: 10px;
  }
}