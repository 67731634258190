:root {
  --white: #FFFFFF;
  --balck: #000000;
  --light-black: #0A093D;
  --primary: #217BF4;
  --dark-primary: #2575E0;
  --light-primary: #F0F7FF;
  --light-primary-v2: #F9FCFF;
  --light-primary-v3: #F3F5F9;
  --light-primary-v4: #FBFCFD;
  --light-primary-v5: #f6f9fc;
  --gray: #808285;
  --gray-v2: #656464;
  --gray-v3: #868E96;
  --gray-v4: #D9D9D9;
  --light-gray: #FCFDFF;
  --light-gray-v2: #F7F7F7;
  --yellow: #FFAF2E;
  --red: #e0182cc4;
  --light-red: #FF858A;
  --light-red-v2: #FDDCD0;
  --light-red-v3: #FBB1A1;
  --light-blue: #5CA1FE;
  --light-green: #9AD3AD;
  --light-yellow: #FFD085;
  --violet: #7827E6;
  --dark-violet: #0A093D;
  --dark-blue: #051259;
  --info-background: #FFF2CC;
  --info-background-blue: #addeffae;
  --info-border: #FFE299;
  --text-info: #7A3400;
  --link: #3392FF;
  --title-content: #051259;
}
