@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-select .MuiOutlinedInput-notchedOutline {
  @apply border-0;
}

.custom-select .MuiOutlinedInput-root {
  @apply rounded-lg;
}

.bg-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(100deg, rgba(255,255,255,1) 0%, rgba(212,231,254,1) 100%);
}

.bg-gradient2 {
  background: rgb(255,255,255);
  background: linear-gradient(140deg, rgba(255,255,255,1) 0%, rgba(212,231,254,1) 100%);
}

/* Set default styles for HTML tags */
.rich-text {
  font-family: 'Poppins', sans-serif; /* Font for the content */
  font-size: 16px; /* Default font size */
  line-height: 1.5; /* Line height for better readability */
  color: #242424; /* Default text color */
}

/* Normalize the margin and padding for the elements */
.rich-text * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set default styles for paragraphs */
.rich-text p {
  margin-bottom: 1.25rem; /* Add space between paragraphs */
  font-size: 16px;
  line-height: 1.5;
}

/* Set default styles for headings */
.rich-text h1, .rich-text h2, .rich-text h3, .rich-text h4, .rich-text h5, .rich-text h6 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold !important;
}


.rich-text h1 {
  font-size: 2.5rem;
  margin-top: 2rem;
}

.rich-text h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
}

.rich-text h3 {
  font-size: 1.75rem;
  margin-top: 1.25rem;
}

.rich-text h4 {
  font-size: 1.5rem;
}

.rich-text h5 {
  font-size: 1.25rem;
}

.rich-text h6 {
  font-size: 1rem;
}



/* Links */
.rich-text a {
  color: #007bff; /* Blue link color */
  text-decoration: none;
}

.rich-text a:hover {
  text-decoration: underline; /* Underline on hover */
}


.rich-text ul, .rich-text ol {
  margin-bottom: 1rem; /* Bottom margin for spacing */
  padding-left: 1.25rem; /* Indentation for list items */
  font-size: 16px; /* Set font size to 16px */
  line-height: 1.5; /* Better line height for readability */
  color: #242424; /* Default text color */
}

.rich-text li {
  margin-bottom: 0.5rem; /* Space between list items */
  font-size: 16px; /* Set font size for list items */
  line-height: 1.5; /* Consistent line height */
}

.rich-text ol {
  list-style-type: decimal; /* Numbered list */
}

.rich-text ul {
  list-style-type: disc; /* Bulleted list */
}

.rich-text li {
  padding-left: 0.5rem; /* Indentation of list items */
}


/* Images */
.rich-text img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0; /* Margin around images */
  border-radius: 8px; /* Rounded corners for images */
}

/* Blockquotes */
.rich-text blockquote {
  border-left: 4px solid #ddd;
  padding-left: 1rem;
  margin: 1rem 0;
  font-style: italic;
  color: #555;
}

/* Code blocks */
.rich-text pre, .rich-text code {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0.5rem;
  font-family: 'Courier New', Courier, monospace;
}

/* Handling tables */
.rich-text table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.25rem;
}

.rich-text th, .rich-text td {
  padding: 0.75rem;
  text-align: left;
  border: 1px solid #ddd;
}

.rich-text th {
  background-color: #f8f8f8;
  font-weight: bold;
}

/* Set normal font and spacing for block elements */
.rich-text blockquote, .rich-text ul, .rich-text ol, .rich-text p {
  margin-bottom: 1rem;
}



