.sarlForm {
    background-color: var(--light-primary-v3);
    min-height: 100vh;
    overflow-x: hidden;
  }
  
  .sarlForm .title {
    width: 100%;
    text-align: center;
    font-family: Inter;
    font-size: 86px;
    font-weight: 700;
    background: linear-gradient(
      180deg,
      rgba(105, 113, 155, 0.1) 0%,
      rgba(62, 70, 108, 0.374) 200%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .sarlForm .cardItemContainer button {
    background-color: var(--white);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    color: var(--title-content);
    width: 260px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    margin: 40px 20px 0 20px;
  }
  
  .sarlForm .cardItem p {
    text-align: start;
    margin-left: 10px;
    width: 75%;
  }
  
  .sarlForm .cardItem .iconContainer {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sarlForm .cardItem:last-child .iconContainer{
    width: 60px;
    height: 60px;
  
  }
  
  .sarlForm .cardItem .iconContainer img{
    filter: brightness(0) invert(1);
  }
  
  .sarlForm .cardItemContainer button:hover{
    background-color: var(--primary);
  }
  
  .sarlForm .cardItemContainer button:hover .iconContainer{
    background-color: var(--white);
  }
  
  .sarlForm .cardItemContainer button:hover .iconContainer img{
    filter: sepia(100%) hue-rotate(210deg) saturate(200%);
  }
  
  .sarlForm .cardItemContainer button:hover p {
    color: var(--white);
  }
  
  .sarlForm h6 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 20px;
    color: var(--title-content);
    text-align: center;
    max-width: 500px;
    margin: auto;
  }
  
  .sarlForm .q1CardContainer {
    max-width: 50vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .sarlForm .contentContainer
  {
    max-width: 600px;
    margin: auto;
    padding-top: 30px;
  }
  
  .sarlForm .radioBoxContainer
  {
    max-width: 600px;
    margin: auto;
    padding-top: 30px;
    display: flex;
    justify-content: center;
  }
  
  .sarlForm input{
    background-color: var(--white);
    height: 30px;
  }
  
  .sarlForm .textContent{
    color: #69719B;
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .sarlForm .textContentQ2Sarl {
    position: relative;
    bottom: 10px;
    left: 2px;
  }
  
  .sarlForm .q2ButtonContainer{
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  
  .sarlForm .link{
    margin-top: 10px;
  }
  
  .sarlForm .link:hover{
    color: var(--primary);
  }
  
  .sarlForm .q3CardContainer {
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .sarlForm .linkContainer {
    max-width: 60vw;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .sarlForm .checkBoxContainer {
    padding-left: 80px;
  }
  