.feedbackCard {
  background: linear-gradient(311.76deg, #D4E7FE -15.24%, #FFFFFF 78.85%);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s ease;
}

.feedbackCard:hover {
  transform: scale(1.02);
}

.customersFeedbackContainer {
  padding: 2rem 1rem;
}

.customersFeedbackText {
  text-align: center;
  margin-bottom: 2rem;
}

.customersFeedbackText .t2 {
  margin: 0 auto;
  line-height: 1.3;
}

.customersFeedbackText .t3 {
  margin: 0 auto;
  line-height: 1.6;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .customersFeedbackContainer {
    padding: 1rem;
  }

  .customersFeedbackText .t1 {
    font-size: 0.875rem;
    margin-top: 1.5rem;
  }

  .customersFeedbackText .t2 {
    font-size: 1.75rem;
    max-width: 90%;
  }

  .customersFeedbackText .t3 {
    font-size: 0.875rem;
    max-width: 95%;
    margin-top: 0.5rem;
  }

  .grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .feedbackCard {
    padding: 1rem;
  }

  .feedbackCard .flex {
    gap: 0.5rem;
  }

  .feedbackCard img {
    width: 48px;
    height: 48px;
  }

  .feedbackCard h3 {
    font-size: 1rem;
  }

  .feedbackCard p {
    font-size: 0.875rem;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .customersFeedbackText .t2 {
    font-size: 2rem;
    max-width: 80%;
  }

  .customersFeedbackText .t3 {
    font-size: 1rem;
    max-width: 85%;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .feedbackCard {
    padding: 1.5rem;
  }

  .feedbackCard h3 {
    font-size: 1.125rem;
  }

  .feedbackCard p {
    font-size: 1rem;
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .customersFeedbackText .t2 {
    font-size: 2.5rem;
    max-width: 70%;
  }

  .customersFeedbackText .t3 {
    font-size: 1.125rem;
    max-width: 60%;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .feedbackCard {
    padding: 2rem;
  }

  .feedbackCard img {
    width: 64px;
    height: 64px;
  }

  .feedbackCard h3 {
    font-size: 1.25rem;
  }

  .feedbackCard p {
    font-size: 1.125rem;
  }
}
