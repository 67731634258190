#contratFormSqueleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#contratFormSqueleton .enregistrerButtonContainer{
  display: flex;
  justify-content: flex-end;
}
#contratFormSqueleton .enregistrerButtonContainer button{
  background-color: var(--white);
  color: var(--title-content);
}
#contratFormSqueleton .stepButtonContainer{
  display: flex;
  justify-content: space-between;
}
#contratFormSqueleton .stepButtonContainer button:nth-child(1){
  background-color: var(--white);
  color: var(--title-content);
}
#contratFormSqueleton .iconMarginLeft{
  margin-left: 10px;
  margin-right: 0px;
}
#contratFormSqueleton .iconMarginRight{
  margin-left: 0px;
  margin-right: 10px;
}
#contratFormSqueleton .progressContainer{
  padding: 30px 0vw;
}