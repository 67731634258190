.contratTravail{
  background-color: var(--light-primary-v3);
}
.titleContratTravail{
  font-family: 'Circular std';
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 80px;
  margin-top: 50px;
  text-align: center;
  padding-left: 5vw;
}

@media (max-width: 767px) {
  .titleContratTravail{
    font-family: 'Circular std';
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 80px;
    margin-top: 100px;
    text-align: center;
    padding-left: 0vw;
  }
}