@media (max-width: 767px) {
  .landingFormContrat{
    padding: 50px;
    margin: auto;
  }
  .landingFormContrat h3{
    color: var(--dark-blue);
    font-weight: 700;
    font-size: 20px;
  }
  .landingFormContrat h6{
    color: var(--dark-blue);
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 20px;
  }
  .landingFormContratBloc{
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 30px;
  }
  .landingFormContratBloc span{
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .landingFormContraIconContainer{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: var(--primary);
  }
  .landingFormContraIcon{
    color: var(--white);
  }
  .landingFormContraItem{
    width: 90px;
    line-height: 1.5;
    font-size: 15px;
    color: var(--dark-blue);
  }
}

@media (min-width: 767px) {
  .landingFormContrat{
    display: flex;
    justify-content: space-between;
    width: 81%;
    margin: auto;
  }
  .landingFormContratContent{
    margin-top: 50px;
    width: 66%;
  }
  .emptyDiv{
    width: 30%;
  }
  .landingFormContratContent h3{
    color: var(--dark-blue);
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .landingFormContratContent h6{
    color: var(--dark-blue);
    font-weight: 400;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .landingFormContratBloc{
    background-color: var(--white);
    display: flex;
    width: 530px;
    height: 130px;
    padding: 20px;
    margin-bottom: 30px;
  }
  .landingFormContratBloc span{
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
  .landingFormContraIconContainer{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: var(--primary);
  }
  .landingFormContraIcon{
    color: var(--white);
  }
  .landingFormContraItem{
    width: 90px;
    line-height: 1.5;
    font-size: 15px;
    color: var(--dark-blue);
  }
}

@media (min-width: 1300px) {
  .landingFormContrat{
    width: 1100px;
    margin: auto;
  }
}