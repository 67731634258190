@import '../../../../assets/theme/font.css';

@media (max-width: 767px) {
  .infoCreationEntrepriseContainer{
    margin-bottom: 150px;
  }
  .infoCreationEntreprise{
    width: 95%;
    margin: auto;
  }
  .bloc{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
  .bloc div{
    margin-top: 50px;
    grid-row: 1/2;
    grid-column: 1/2;
    padding-left: 3.5%;
    z-index: 2;
  }
  .number{
    background: rgb(89,164,255);
    background: linear-gradient(135deg, rgba(89,164,255,0.3) 14%, var(--light-primary) 40%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 190px;
    font-weight: 800;
    height: 155px;
    margin: 0;
    position: relative;
    grid-row: 1/2;
    grid-column: 1/2;
    font-family: 'Mukta';
    bottom: 85px;
    padding-left: 2%;
  }
  .bloc h1{
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 700;
    color: var(--light-black);
  }
  .bloc div p, .bloc div li{
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    color: var(--gray-v2);
    width: 99%;
  }
}

@media (min-width: 767px) {
  .infoCreationEntrepriseContainer{
    margin-bottom: 150px;
  }
  .infoCreationEntreprise{
    width: 95%;
    margin: auto;
  }
  .bloc{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
  .bloc div{
    margin-top: 120px;
    grid-row: 1/2;
    grid-column: 1/2;
    padding-left: 3.5%;
    z-index: 2;
  }
  .number{
    background: rgb(89,164,255);
    background: linear-gradient(135deg, rgba(89,164,255,0.3) 10%, var(--light-primary) 16%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 300px;
    font-weight: 800;
    height: 250px;
    margin: 0;
    position: relative;
    grid-row: 1/2;
    grid-column: 1/2;
    font-family: 'Mukta';
    bottom: 100px;
    padding-left: 2%;
  }
  .bloc h1{
    font-family: 'Inter';
    font-size: 29px;
    font-weight: 700;
    color: var(--light-black);
  }
  .bloc div p, .bloc div li{
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 400;
    color: var(--gray-v2);
    width: 99%;
  }
}

@media (min-width: 1300px) {
  .infoCreationEntreprise{
    width: 1100px;
    margin: auto;
  }
}