.offreDomiciliation .title{
  font-family: 'Inter';
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
  color: var(--title-content);
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.itemOffreDomiciliation{
  border: solid 1px #dee2e6;
  border-radius: 10px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.itemOffreDomiciliation .leftBlock{
  width: 60%;
}

.itemOffreDomiciliation h3{
  font-weight: 700;
  font-size: 24px;
  color: var(--title-content);
}

.itemOffreDomiciliation ul{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.itemOffreDomiciliation li{
  list-style-type: disc;
  color: rgba(5, 18, 89, .8);
}

.itemOffreDomiciliation img{
  width: 200px;
  height: 200px;
}

@media (max-width: 890px){
  .itemOffreDomiciliation{
    width: 90%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .itemOffreDomiciliation .leftBlock{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .itemOffreDomiciliation .leftBlock ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .itemOffreDomiciliation .leftBlock{
    order: 2;
  }

  .itemOffreDomiciliation img{
    order: 1;
  }
}

@media (min-width: 1300px) {
  .offreDomiciliation{
    width: 1100px;
    margin: auto;
  }
}