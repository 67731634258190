.sarl-section3 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sarl-section3-title {
  width: 50%;
  color: #051259;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1px;
}