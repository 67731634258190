.customSelect{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--light-black);
  font-size: 14px;
  margin-bottom: 20px;
  width: 50%;
}
.customSelect .sizedBox{
  height: 5px;
}
.MuiMenuItem-root{
  font-size: 14px;
}