@import '../../assets/theme/font.css';

@media (max-width: 767px) {
  .contactContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: var(--light-primary-v2);
  }
  .contact{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .contactInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
  }
  .contactInfo h1{
    font-size: 40px;
    color: var(--light-black);
    font-family: 'Rubik';
    font-weight: 500;
    text-align: center;
  }
  .contactInfo p{
    font-size: 15px;
    color: var(--gray-v2);
    line-height: 1.5;
    font-family: 'Rubik';
    text-align: center;
    margin: 0 4vw;
  }
  .hr{
    border-top: dashed 1px var(--gray);
    width: 65%;
    margin: 50px 0;
  }
  .contactInfo h3{
    font-size: 22px;
    font-family: 'Rubik';
    font-weight: 500;
  }
  .infoText{
    display: flex;
    align-items: center;
    font-size: 18px !important;
    font-family: 'Rubik';
  }
  .infoText .icon{
    margin-right: 5px;
  }
  .contactForm{
    background-color: var(--white);
    height: 490px;
    margin: 50px;
    opacity: 0;
    animation-name: displayForm;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
  .contactForm h3{
    color: var(--gray-v2);
    font-size: 25px;
    font-family: 'Rubik';
    font-weight: 400;
  }
  .contactForm .name{
    display: flex;
    justify-content: space-between;
  }
  .contactForm .inputPrenom{
    margin-right: 20px;
  }
  .contactForm div{
    margin-bottom: 10px;
  }
  .contactForm .form{
    width: 80%;
    margin: auto;
  }
  .contactForm .form button{
    width: 100%;
    height: 45px;
    margin: auto;
  }
  @keyframes displayForm {
    from{
      opacity: 0;
    } to {
      opacity: 1;
    }
  }
}

@media (min-width: 767px) {
  .contactContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: var(--light-primary-v2);
  }
  .contact{
    display: flex;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .contactInfo{
    width: 39%;
    margin: 0 1vw 0 4vw;
    padding-top: 16px;
  }
  .contactInfo h1{
    font-size: 40px;
    color: var(--light-black);
    font-family: 'Rubik';
    font-weight: 500;
  }
  .contactInfo p{
    font-size: 15px;
    color: var(--gray-v2);
    line-height: 1.5;
    font-family: 'Rubik';
  }
  .hr{
    border-top: dashed 1px var(--gray);
    width: 65%;
    margin: 50px 0;
  }
  .contactInfo h3{
    font-size: 20px;
    font-family: 'Rubik';
    font-weight: 500;
  }
  .infoText{
    display: flex;
    align-items: center;
    font-size: 15px !important;
    font-family: 'Rubik';
  }
  .infoText .icon{
    margin-right: 5px;
  }
  .contactForm{
    background-color: var(--white);
    width: 48%;
    height: 490px;
    margin: 50px;
    opacity: 0;
    animation-name: displayForm;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
  .contactForm h3{
    color: var(--gray-v2);
    font-size: 20px;
    font-family: 'Rubik';
    font-weight: 400;
  }
  .contactForm .name{
    display: flex;
    justify-content: space-between;
  }
  .contactForm .inputPrenom{
    margin-right: 20px;
  }
  .contactForm div{
    margin-bottom: 10px;
  }
  .contactForm .form{
    width: 80%;
    margin: auto;
  }
  .contactForm .form button{
    width: 100%;
    height: 45px;
    margin: auto;
  }
  @keyframes displayForm {
    from{
      opacity: 0;
    } to {
      opacity: 1;
    }
  }
}

@media (min-width: 1000px) {
  .contactContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: var(--light-primary-v2);
  }
  .contact{
    display: flex;
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .contactInfo{
    width: 39%;
    margin: 0 1vw 0 4vw;
    padding-top: 16px;
  }
  .contactInfo h1{
    font-size: 58px;
    color: var(--light-black);
    font-family: 'Rubik';
    font-weight: 500;
  }
  .contactInfo p{
    font-size: 20px;
    color: var(--gray-v2);
    line-height: 1.5;
    font-family: 'Rubik';
  }
  .hr{
    border-top: dashed 1px var(--gray);
    width: 65%;
    margin: 50px 0;
  }
  .contactInfo h3{
    font-size: 22px;
    font-family: 'Rubik';
    font-weight: 500;
  }
  .infoText{
    display: flex;
    align-items: center;
    font-size: 18px !important;
    font-family: 'Rubik';
  }
  .infoText .icon{
    margin-right: 5px;
  }
  .contactForm{
    background-color: var(--white);
    width: 48%;
    height: 490px;
    margin: 50px;
    opacity: 0;
    animation-name: displayForm;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
  .contactForm h3{
    color: var(--gray-v2);
    font-size: 25px;
    font-family: 'Rubik';
    font-weight: 400;
  }
  .contactForm .name{
    display: flex;
    justify-content: space-between;
  }
  .contactForm .inputPrenom{
    margin-right: 20px;
  }
  .contactForm div{
    margin-bottom: 10px;
  }
  .contactForm .form{
    width: 80%;
    margin: auto;
  }
  .contactForm .form button{
    width: 100%;
    height: 45px;
    margin: auto;
  }
  @keyframes displayForm {
    from{
      opacity: 0;
    } to {
      opacity: 1;
    }
  }
}

@media (min-width: 1300px) {
  .contact{
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}