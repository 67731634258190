@media (max-width: 767px) {
  .dernierArticles{
    padding-top: 40px;
    padding-bottom: 30px;
    background-color: var(--white);
  }
  .dernierArticles h1{
    color: var(--light-black);
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
  }
  .articleContainer{
    margin: 0 50px;
  }
  .article{
    cursor: pointer;
    margin-bottom: 50px;
  }
  .article img{
    width: 100%;
  }
}

@media (min-width: 767px) {
  .dernierArticles{
    padding-top: 40px;
    padding-bottom: 30px;
    background-color: var(--white);
  }
  .dernierArticles h1{
    color: var(--light-black);
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
  }
  .articleContainer{
    margin: 0 50px;
    display: flex;
    justify-content: space-between;
  }
  .article {
    cursor: pointer;
    width: 30%;
  }
  .article img{
    width: 100%;
  }
  .article:hover img{
    opacity: 0.9;
  }
  .article:hover h3{
    color: var(--gray);
  }
  .article:hover p{
    color: var(--primary);
  }
}

@media (min-width: 1300px) {
  .dernierArticlesContainer{
    background-color: var(--white);
  }
  .dernierArticles{
    width: 1300px;
    margin: auto;
  }
}