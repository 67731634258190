.btn-form {
  width: 100px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid #e9e8e8;
  cursor: pointer;
}

.btn-icon {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
}
.btn-icon img {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
}

.btn-text {
  padding: 0;
  margin: 0;
  color: #051259;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.btn-form:hover,
.btn-form:hover .btn-text,
.active,
.active > .btn-text {
  background: #3392ff;
  color: #fff;
}

.btn-form:hover .btn-icon img,
.active .btn-icon img {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(359deg)
    brightness(100%) contrast(100%);
}
