.contratLocation{
  background-color: var(--light-primary-v3);
}
.contratLocation .modele{
  width: 16%;
}
.otherLinksContratLocation{
  display: flex;
  justify-content: space-around;
  padding: 50px;
}
.otherLinksContratLocation .leftOtherLinksContratLocation, .otherLinksContratLocation .rightOtherLinksContratLocation{
  width: 45%;
}
.leftOtherLinksContratLocationItem .link, .rightOtherLinksContratLocationItem .link{
  color: var(--link);
  text-decoration: none;
  font-weight: 600;
}
.leftOtherLinksContratLocationItem .link:hover, .rightOtherLinksContratLocationItem .link:hover{
  color: var(--black);
}
.leftOtherLinksContratLocationItem p, .rightOtherLinksContratLocationItem p{
  font-size: 14px;
  color: var(--gray-v2);
  margin-top: 5px;
  max-width: 450px;
}
.titleContratLocation{
  font-family: 'Circular std';
  font-size: 23px;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
  padding-left: 5vw;
}
.descriptionContratLocation{
  font-size: 18px;
  width: 93%;
  margin: auto;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .otherLinksContratLocation{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 50px;
  }
  .otherLinksContratLocation .leftOtherLinksContratLocation, .otherLinksContratLocation .rightOtherLinksContratLocation{
    width: 100%;
  }
  .leftOtherLinksContratLocationItem .link, .rightOtherLinksContratLocationItem .link{
    color: var(--link);
    text-decoration: none;
    font-weight: 600;
  }
  .leftOtherLinksContratLocationItem .link:hover, .rightOtherLinksContratLocationItem .link:hover{
    color: var(--black);
  }
  .leftOtherLinksContratLocationItem p, .rightOtherLinksContratLocationItem p{
    font-size: 14px;
    color: var(--gray-v2);
    margin-top: 5px;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .titleContratLocation{
    font-family: 'Circular std';
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
    padding-left: 0vw;
  }
  .descriptionContratLocation{
    font-size: 18px;
    width: 93%;
    margin: auto;
    margin-bottom: 15px;
    text-align: center;
  }
}
@media (min-width: 767px) and ((max-width: 1050px)) {
  .contratLocation .modeleContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  .contratLocation .modele{
   display: none;
  }
  .contratLocation .modele-767{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    margin-top: 20px;
    padding: 0 20px;
    width: 60vw;
    cursor: pointer;
  }
  .contratLocation .modele-767:hover p, .modele-767:hover .icon{
    opacity: 0.8;
  }
  .contratLocation .modele-767 p{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: var(--dark-blue);
  }
  .contratLocation .modele-767-link{
    text-decoration: none;
  }
}
@media (min-width: 1300px) {
  .otherLinksContratLocation{
    width: 1150px;
    margin: auto;
    justify-content: space-between;
  }
  .descriptionContratLocation{
    font-size: 18px;
    width: 1200px;
    margin: auto;
    margin-bottom: 15px;
  }
}