.titleContratContentContainer{
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.emptyDiv{
  width: 30%;
}

.titleContratContent{
  font-family: 'Circular std';
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 80px;
  margin-top: 50px;
  width: 66%;
}

@media (min-width: 1300px) {
  .titleContratContentContainer{
    width: 1100px;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .titleContratContent{
    font-family: 'Circular std';
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 80px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    padding-left: 0vw;
  }
}