@media (max-width: 767px) {
  .creationEntrepriseContainer .demarcheCreation h1 {
    color: var(--light-black);
    margin-bottom: 50px;
    margin-top: 40px;
    font-size: 30px;
    text-align: center;
  }
  .creationEntrepriseContainer .blockIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .creationEntrepriseContainer .blockIcon img{
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
  }
  .creationEntrepriseContainer .blockIcon p{
    color: var(--gray);
    font-size: 16px;
    font-weight: 700;
    width: 140px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 30px;
    opacity: 0.8;
    text-align: center;
  }
  .creationEntrepriseContainer .process{
    width: 95%;
    margin-left: 2.5%;
  }
}

@media (min-width: 767px) {
  .creationEntrepriseContainer .demarcheCreation{
    padding-top: 60px;
  }
  .creationEntrepriseContainer .demarcheCreation h1 {
    color: var(--light-black);
    margin-bottom: 50px;
    margin-top: 0;
    font-size: 36px;
    text-align: center;
  }
  .creationEntrepriseContainer .blockIconContainer{
    display: flex;
    justify-content: space-evenly;
  }
  .creationEntrepriseContainer .blockIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .creationEntrepriseContainer .blockIcon img{
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
  }
  .creationEntrepriseContainer .blockIcon p{
    color: var(--gray);
    font-size: 16px;
    font-weight: 700;
    width: 140px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 30px;
    opacity: 0.8;
    text-align: center;
  }
  .creationEntrepriseContainer .process{
    width: 90%;
    margin-left: 5.5%;
  }
}

@media (min-width: 1300px) {
  .creationEntrepriseContainer .demarcheCreation{
    width: 1100px;
    margin: auto;
  }
}